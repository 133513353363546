// gatsby-browser.js
import React from 'react';
import 'core-js';
import './src/styles/global.css';
import { BrowserRouter as Router } from 'react-router-dom';

// Wrap the root element in the Router for client-side navigation
export const wrapRootElement = ({ element }) => {
  return <Router>{element}</Router>;
};
